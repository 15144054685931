import React, { FC, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import styles from "./trendChart.module.scss";
import { GetTrendsQuery } from "../../graphql/generated/graphql";
import { useTrendsQueryResult } from "../../AppProvider";
import * as htmlToImage from "html-to-image";
import { energyUseIntensity } from "../../assets/data/filters";
import { CircularProgress } from "@mui/material";

interface TrendChartProps {
  metric: any;
  caption: any;
  chartTitle: any;
  chartCount: any;
}

const TrendChart: FC<TrendChartProps> = ({
  metric,
  caption,
  chartTitle,
  chartCount,
}) => {
  const { data } = useTrendsQueryResult<GetTrendsQuery>();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(50);
  const [containerHeight, setContainerHeight] = useState(50);
  const [marginRight, setMarginRight] = useState(120);
  const chartRef = useRef(null);
  let [downloading, setDownloading] = useState(false);

  //   const filters = [];

  //   // Property Types
  //   if (
  //     (ptCategories && ptCategories.length) ||
  //     (ptSubcategories && ptSubcategories.length)
  //   ) {
  //     filters.push(
  //       `Property Types: ${[...ptCategories, ...ptSubcategories].join(", ")}`
  //     );
  //   } else {
  //     filters.push("Property Types: All");
  //   }

  //   // Gross Floor Area
  //   if ((gfaGroup2s && gfaGroup2s.length) || (gfaGroups && gfaGroups.length)) {
  //     filters.push(
  //       `Gross Floor Area: ${[...gfaGroup2s, ...gfaGroups].join(", ")}`
  //     );
  //   } else {
  //     filters.push("Gross Floor Area: All");
  //   }

  //   // Location (State, CBSA, Climate Zone)
  //   if (location === "state") {
  //     if (stateProvinceNames && stateProvinceNames.length) {
  //       filters.push(
  //         `States: ${
  //           stateProvinceNames.length <= 5
  //             ? stateProvinceNames.join(", ")
  //             : `${stateProvinceNames.length} states`
  //         }`
  //       );
  //     } else {
  //       filters.push("States: All");
  //     }
  //   } else if (location === "csa") {
  //     if ((csa_area && csa_area.length) || (csa_city && csa_city.length)) {
  //       filters.push(`CBSAs: ${[...csa_area, ...csa_city].join(", ")}`);
  //     } else {
  //       filters.push("CBSAs: All");
  //     }
  //   } else if (location === "climate") {
  //     if (climateZone && climateZone.length) {
  //       filters.push(
  //         `Climate Zones: ${
  //           climateZone.length <= 5
  //             ? climateZone.join(", ")
  //             : `${climateZone.length} climate zones`
  //         }`
  //       );
  //     } else {
  //       filters.push("Climate Zones: All");
  //     }
  //   } else {
  //     filters.push("Location: All");
  //   }

  //   // ENERGY STAR Certified
  //   filters.push(
  //     `ENERGY STAR Certified: ${
  //       isEnergyStarCertified === true
  //         ? "Yes"
  //         : isEnergyStarCertified === false
  //         ? "No"
  //         : "Show All"
  //     }`
  //   );

  //   // Year Built
  //   if (yearBuiltGroup && yearBuiltGroup.length) {
  //     filters.push(`Years Built: ${yearBuiltGroup.join(", ")}`);
  //   } else {
  //     filters.push("Years Built: All");
  //   }

  //   // Weekly Operating Hours
  //   if (weeklyHoursGroup && weeklyHoursGroup.length) {
  //     filters.push(`Weekly Operating Hours: ${weeklyHoursGroup.join(", ")}`);
  //   } else {
  //     filters.push("Weekly Operating Hours: All");
  //   }

  //   return `Applied filters: ${filters.join("; ")}`;
  // };

  useEffect(() => {
    setContainerWidth(
      d3.select("#trendChartContainer").node().getBoundingClientRect().width
    );
    setContainerHeight(
      d3.select("#trendChartContainer").node().getBoundingClientRect().height
    );
  }, []);

  const downloadImage = (dataUrl: any, filename: string) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const handleExportImage = () => {
    const captionElement = document.querySelector(`.${styles.chartCaption}`) as HTMLElement;
    const countElement = document.querySelector(`.${styles.countCaption}`) as HTMLElement;
    const titleElement = document.querySelector(`.${styles.chartTitle}`) as HTMLElement;
    setDownloading(true);
  
    // Temporarily show the caption
    if (captionElement) {
      captionElement.style.display = "block";
    }

    if (countElement) {
      countElement.style.display = "block";
    }

    if (titleElement) {
      titleElement.style.display = "block";
    }
  
    const filename = `trends-${metric}-${new Date()
      .toISOString()
      .substring(0, 10)}.png`;
  
    if (chartRef.current) {
      htmlToImage
        .toPng(chartRef.current)
        .then((dataUrl) => {
          downloadImage(dataUrl, filename);
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        })
        .finally(() => {
          // Hide the caption again after the image is generated
          if (captionElement) {
            captionElement.style.display = "none";
          }
          if (countElement) {
            countElement.style.display = "none";
          }
          if (titleElement) {
            titleElement.style.display = "none";
          }
        });
    }
    setDownloading(false);
  };
  

  const handleResize = () => {
    const trendChartContainer = d3.select("#trendChartContainer").node();
    if (trendChartContainer) {
      setContainerWidth(trendChartContainer.getBoundingClientRect().width);
    }
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (!data?.getTrends?.results || data.getTrends.results.length === 0)
      return;

    const trends = data.getTrends.results;

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
      .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

    svg.selectAll("*").remove(); // Clear any existing content in the SVG

    const flattenedTrends = trends.flatMap((trend) => {
      const years = trend.yearreported || [];
      const medians = trend.median || [];
      return years.map((year, i) => ({
        group: trend.group || "unknown",
        yearreported: year,
        median: Math.round(medians[i] ?? 0),
      }));
    });

    // Calculate width of longest label
    const groupNames = flattenedTrends.map((d) => d.group);
    const longestLabelWidth =
      d3.max(
        groupNames.map((name) => {
          const text = svg
            .append("text")
            .attr("font-size", "12px")
            .attr("visibility", "hidden")
            .text(name);
          const bbox = text.node().getBBox();
          text.remove();
          return bbox.width;
        })
      ) || 120;

    setMarginRight(Math.max(120, longestLabelWidth + 20)); // Set margin-right to be sufficient for the longest label

    const x = d3
      .scaleUtc()
      .domain(
        d3.extent(
          flattenedTrends,
          (d: any) => new Date(d.yearreported, 0, 1)
        ) as [Date, Date]
      )
      .range([60, containerWidth - marginRight]); // Adjusted the starting point for x

    svg
      .append("g")
      .attr("transform", `translate(0,${containerHeight - 30})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(d3.timeYear.every(1))
          .tickFormat(d3.timeFormat("%Y"))
          .tickSizeOuter(0)
      );
    function updateOpacity(eventType: string) {
      // Check if there are any active elements
      const hasActive =
        svg
          .selectAll(".g")
          .filter(function (this: SVGGElement) {
            return d3.select(this).classed(styles.active);
          })
          .size() > 0;

      // Update opacity based on the presence of active elements and hover state
      svg.selectAll(".g").each(function (this: SVGGElement, event: any) {
        const currentElement = d3.select(this);
        const isActive = currentElement.classed(styles.active);
        const isHovered = currentElement.classed(styles.hovered);

        if (hasActive) {
          // If there are active elements, adjust opacity accordingly
          if (isActive) {
            currentElement.transition().style("opacity", 1);
          } else if (isHovered) {
            currentElement.transition().style("opacity", 1);
          } else {
            currentElement.transition().style("opacity", 0.1);
          }
        } else {
          // If no active elements, adjust opacity based on hover state
          if (isHovered) {
            currentElement.transition().style("opacity", 1);
          } else {
            currentElement
              .transition()
              .style("opacity", eventType === "mouseout" ? 1 : 0.1); // Ensure all paths are fully opaque
          }
        }
      });
    }
    const y = d3
      .scaleLinear()
      .domain([
        d3.min(flattenedTrends, (d: any) => d.median) ?? 0,
        d3.max(flattenedTrends, (d: any) => d.median) ?? 0,
      ])
      .nice()
      .range([containerHeight - 30, 30]);

    const color = d3
      .scaleOrdinal()
      .domain(flattenedTrends.map((d) => d.group))
      .range(d3.schemeCategory10);

    const yAxis = d3.axisLeft(y).ticks(5);

    svg.append("g").attr("transform", `translate(30, 0)`).call(yAxis);

    // Add y-axis label
    svg
      .append("text")
      .attr("x", 30)
      .attr("y", 15)
      .attr("text-anchor", "start")
      .attr("font-size", "12px")
      .attr("font-weight", "bold")
      .text(
        `${energyUseIntensity.find((x) => x.name === metric)?.value} ${
          energyUseIntensity.find((x) => x.name === metric)?.unit
        }`
      );

    const lineGenerator = d3
      .line()
      .x((d: any) => x(new Date(d.yearreported, 0, 1)))
      .y((d: any) => y(d.median));

    const series = svg
      .append("g")
      .selectAll("g")
      .data(d3.group(flattenedTrends, (d: any) => d.group))
      .join("g");

    series.each(function (this: SVGGElement, d: any) {
      const groupData = d[1];
      const groupColor = color(d[0]) as string;

      const element = d3.select(this);
      element.attr("class", "g");

      if (groupData.length > 1) {
        element
          .append("path")
          .attr("fill", "none")
          .attr("stroke", groupColor)
          .attr("stroke-width", 1.5)
          .attr("class", styles.path)
          .transition()
          .duration(750)
          .attr("d", lineGenerator(groupData));

        element
          .append("line")
          .attr(
            "x1",
            x(new Date(groupData[groupData.length - 1].yearreported, 0, 1))
          )
          .attr("y1", y(groupData[groupData.length - 1].median))
          .attr("x2", containerWidth - marginRight)
          .attr("y2", y(groupData[groupData.length - 1].median))
          .attr("stroke", groupColor)
          .attr("stroke-width", 1.5)
          .style("stroke-dasharray", "3, 3")
          .attr("class", styles.noDataLine);

        element
          .append("g")
          .attr("stroke-linecap", "round")
          .attr("stroke-linejoin", "round")
          .attr("text-anchor", "middle")
          .attr("class", styles.defaultG)
          .selectAll("text")
          .data(groupData)
          .join("text")
          .attr("class", styles.text)
          .attr("dy", "0.35em")
          .attr("x", (d: any) => x(new Date(d.yearreported, 0, 1)))
          .attr("y", (d: any) => y(d.median))
          .text((d: any) => Math.round(d.median))
          .clone(true)
          .lower()
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 6);

        element
          .append("text")
          .attr("x", containerWidth - marginRight + 10)
          .attr("y", y(groupData[groupData.length - 1].median))
          .attr("dy", "0.35em")
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
          .attr("fill", groupColor)
          .text(`${d[0]}`)
          .attr("class", styles.text)
          .clone(true)
          .lower()
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 6);

        element
          .on("click", function (this: SVGGElement) {
            const clickedElement = d3.select(this);
            const wasActive = clickedElement.classed(styles.active);

            // Toggle the 'active' class on the clicked element
            clickedElement.classed(styles.active, !wasActive);
            clickedElement.raise();

            // Update opacity based on current state
            updateOpacity("click");
          })
          .on("mouseover", function (this: SVGGElement) {
            const hoveredElement = d3.select(this);
            hoveredElement.classed(styles.hovered, true);
            hoveredElement.raise();

            // Update opacity based on current state
            updateOpacity("mouseover");
          })
          .on("mouseout", function (this: SVGGElement) {
            const hoveredElement = d3.select(this);
            hoveredElement.classed(styles.hovered, false);

            // Update opacity based on current state
            updateOpacity("mouseout");
          });
      }
    });

    svg
      .transition()
      .duration(750)
      .attr("width", containerWidth)
      .attr("height", containerHeight)
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`);
  }, [data, containerWidth, containerHeight, marginRight, metric]);

  return (
    <div className={styles.TrendChart}>
      <div className="trend-chart-header">
        <button
          className="usa-button usa-button--active"
          onClick={handleExportImage}
        >
          Export Chart as Image
        </button>
      </div>
      


      {!downloading ? (
       <div
       ref={chartRef}
       id="trendChartContainer"
       className={styles.TrendChart}
     >
      <h2 className={styles.chartTitle}>{chartTitle}</h2>
      <p className={styles.countCaption}>{chartCount}</p>
      <p className={styles.chartCaption}>{caption}</p>
       <svg ref={svgRef}></svg>
     </div>
      ) : (
        <div className="loading-container">
          <div className="loading-container-inner">
            <p>Downloading your chart...</p>
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
};

export default TrendChart;
