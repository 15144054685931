import React from 'react';
import './icons.scss'; // Import the SCSS file

export default function PlusIcon(PlusIconProps: any) {
    const viewBox = `0 0 ${24 * PlusIconProps.scale} ${24 * PlusIconProps.scale}`;
    
    return (
        <svg
            className="icon-svg" // Apply the SCSS class here
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            width={15.139 * PlusIconProps.scale}
            height={18.022 * PlusIconProps.scale}
        >
            <path
                d="M12.8,11.2V5c0-0.4-0.3-0.8-0.8-0.8S11.2,4.6,11.2,5v6.2H5c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.2V19
		c0,0.4,0.3,0.7,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-6.2H19c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.7-0.8-0.8H12.8z"
                transform={`translate(0 0) scale(${PlusIconProps.scale})`}
                fill={PlusIconProps.color}
            />
        </svg>
    );
}
