import { useQuery } from "@apollo/client";
import { graphql } from "../../graphql/generated/gql";
import DataExplorerPage from "./dataExplorerPage";
import { FC } from "react";
import ErrorPage from "../errorPage/errorPage";
import { useAppContext } from "../../AppProvider";

// NOTE: changes made to the query need to be run through npm run codemon or the codemon must be watching for changes
const getResultsQuery = graphql(/* GraphQL */ `
  query GetResults(
    $metric: Metric!
    $yearReported: Int!
    $groupBy: Grouping!
    $thenBy: Grouping
    $stateProvinceNames: [String]
    $counties: [String]
    $yearBuiltGroups: [String]
    $gfaGroups: [String]
    $gfaGroup2s: [String]
    $csa_city: [String]
    $csa_area: [String]
    $climateZone: [String]
    $isEnergyStarCertified: Boolean
    $workerDensityGroups: [String]
    $weeklyHoursGroup: [String]
    $ptCategories: [String]
    $ptSubcategories: [String]
    $withMean: Boolean!
    $withMedian: Boolean!
    $withFifthPercentile: Boolean!
    $withTwentyFifthPercentile: Boolean!
    $withSeventyFifthPercentile: Boolean!
    $withNinetyFifthPercentile: Boolean!
  ) {
    getResults(
      metric: $metric
      yearReported: $yearReported
      groupBy: $groupBy
      thenBy: $thenBy
      stateProvinceNames: $stateProvinceNames
      counties: $counties
      yearBuiltGroups: $yearBuiltGroups
      gfaGroups: $gfaGroups
      gfaGroup2s: $gfaGroup2s
      csa_city: $csa_city
      csa_area: $csa_area
      climateZone: $climateZone
      isEnergyStarCertified: $isEnergyStarCertified
      workerDensityGroups: $workerDensityGroups
      weeklyHoursGroup: $weeklyHoursGroup
      ptCategories: $ptCategories
      ptSubcategories: $ptSubcategories
      withMean: $withMean
      withMedian: $withMedian
      withFifthPercentile: $withFifthPercentile
      withTwentyFifthPercentile: $withTwentyFifthPercentile
      withSeventyFifthPercentile: $withSeventyFifthPercentile
      withNinetyFifthPercentile: $withNinetyFifthPercentile
    ) {
      results {
        group
        rowCount
        median @include(if: $withMedian)
        mean @include(if: $withMean)
        fifthPercentile @include(if: $withFifthPercentile)
        twentyFifthPercentile @include(if: $withTwentyFifthPercentile)
        seventyFifthPercentile @include(if: $withSeventyFifthPercentile)
        ninetyFifthPercentile @include(if: $withNinetyFifthPercentile)
      }
      count
    }
  }
`);


interface DataExplorerPageContainerProps {
  resetFilters: any;
  setYearReported: any;
  setMetric: any;
  setIsEnergyStarCertified: any;
  setYearBuiltGroups: any;
  setGfaGroup2s: any;
  setGfaGroups: any;
  setThenBy: any;
  setGroupBy: any;
  setLocation: any;
  metric: any;
  yearReported: number;
  groupBy: any;
  thenBy: any;
  stateProvinceNames: any;
  counties: any;
  setCounties: any;
  yearBuiltGroups: any;
  gfaGroups: any;
  gfaGroup2s: any;
  isEnergyStarCertified: any;
  setStateProvinceNames: any;
  workerDensityGroups: any;
  weeklyHoursGroup: any;
  ptCategories: any;
  ptSubcategories: any;
  csa_area: any;
  csa_city: any;
  setCsaCity: any;
  setCsaArea: any;
  climateZone: any;
  setWeeklyHoursGroup: any;
  setWorkerDensityGroups: any;
  setPtSubcategories: any;
  setPtCategories: any;
  setClimateZone: any;
  drawerOpen: any;
  setDrawerOpen: any;
  withMean: any;
  setWithMean: any;
  withMedian: any;
  setWithMedian: any;
  withFifthPercentile: any;
  setWithFifthPercentile: any;
  withTwentyFifthPercentile: any;
  setWithTwentyFifthPercentile: any;
  withSeventyFifthPercentile: any;
  setWithSeventyFifthPercentile: any;
  withNinetyFifthPercentile: any;
  setWithNinetyFifthPercentile: any;
  location: any;
  isFilterPanelLoading: any;
  setIsFilterPanelLoading: any;
}

const DataExplorerPageContainer: FC<DataExplorerPageContainerProps> = ({
  resetFilters,
  setMetric,
  setYearReported,
  setIsEnergyStarCertified,
  setYearBuiltGroups,
  setStateProvinceNames,
  setCounties,
  setThenBy,
  setGroupBy,
  setGfaGroups,
  setGfaGroup2s,
  setLocation,
  metric,
  yearReported,
  csa_area,
  csa_city,
  climateZone,
  groupBy,
  thenBy,
  stateProvinceNames,
  counties,
  yearBuiltGroups,
  gfaGroups,
  gfaGroup2s,
  isEnergyStarCertified,
  workerDensityGroups,
  weeklyHoursGroup,
  ptCategories,
  ptSubcategories,
  setWeeklyHoursGroup,
  setWorkerDensityGroups,
  setPtSubcategories,
  setPtCategories,
  setCsaArea,
  setCsaCity,
  setClimateZone,
  drawerOpen,
  setDrawerOpen,
  withMean,
  setWithMean,
  withMedian,
  setWithMedian,
  withFifthPercentile,
  setWithFifthPercentile,
  withTwentyFifthPercentile,
  setWithTwentyFifthPercentile,
  withSeventyFifthPercentile,
  setWithSeventyFifthPercentile,
  withNinetyFifthPercentile,
  setWithNinetyFifthPercentile,
  location,
  isFilterPanelLoading,
  setIsFilterPanelLoading,
}) => {
  const state = useAppContext();
  const { data, loading, error } = useQuery(getResultsQuery, {
    skip: state.isTrendsShown,
    variables: {
      metric,
      yearReported,
      groupBy,
      thenBy,
      stateProvinceNames,
      counties,
      yearBuiltGroups,
      gfaGroups,
      gfaGroup2s,
      isEnergyStarCertified,
      workerDensityGroups,
      weeklyHoursGroup,
      ptCategories,
      ptSubcategories,
      csa_area,
      csa_city,
      climateZone,
      withMean,
      withMedian,
      withFifthPercentile,
      withTwentyFifthPercentile,
      withSeventyFifthPercentile,
      withNinetyFifthPercentile,
    }
  });


  
  if (error) return <ErrorPage message={error.message} />;

  return (
    <DataExplorerPage
      resetFilters={resetFilters}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
      setPtSubcategories={setPtCategories}
      setPtCategories={setPtSubcategories}
      setCsaArea={setCsaArea}
      setCsaCity={setCsaCity}
      csa_area={csa_area}
      csa_city={csa_city}
      climateZone={climateZone}
      setClimateZone={setClimateZone}
      ptCategories={ptSubcategories}
      ptSubcategories={ptCategories}
      workerDensityGroups={workerDensityGroups}
      setWorkerDensityGroups={setWorkerDensityGroups}
      setWeeklyHoursGroup={setWeeklyHoursGroup}
      weeklyHoursGroup={weeklyHoursGroup}
      counties={counties}
      setCounties={setCounties}
      loading={loading}
      setStateProvinceNames={setStateProvinceNames}
      stateProvinceNames={stateProvinceNames}
      setThenBy={setThenBy}
      setGroupBy={setGroupBy}
      groupBy={groupBy}
      thenBy={thenBy}
      gfaGroup2s={gfaGroup2s}
      gfaGroups={gfaGroups}
      setGfaGroups={setGfaGroups}
      setGfaGroup2s={setGfaGroup2s}
      yearBuiltGroups={yearBuiltGroups}
      setYearBuiltGroups={setYearBuiltGroups}
      isEnergyStarCertified={isEnergyStarCertified}
      setIsEnergyStarCertified={setIsEnergyStarCertified}
      yearReported={yearReported}
      setYearReported={setYearReported}
      metric={metric}
      setMetric={setMetric}
      withMean={withMean}
      setWithMean={setWithMean}
      withMedian={withMedian}
      setWithMedian={setWithMedian}
      withTwentyFifthPercentile={withTwentyFifthPercentile}
      setWithTwentyFifthPercentile={setWithTwentyFifthPercentile}
      withSeventyFifthPercentile={withSeventyFifthPercentile}
      setWithSeventyFifthPercentile={setWithSeventyFifthPercentile}
      results={data}
      withFifthPercentile={withFifthPercentile}
      setWithFifthPercentile={setWithFifthPercentile}
      withNinetyFifthPercentile={withNinetyFifthPercentile}
      setWithNinetyFifthPercentile={setWithNinetyFifthPercentile}
      location={location}
      setLocation={setLocation}
      isFilterPanelLoading={isFilterPanelLoading}
      setIsFilterPanelLoading={setIsFilterPanelLoading}
    />
  );
};

export default DataExplorerPageContainer;
