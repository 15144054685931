export const tableJSON = [
    {
        "Display by": "All Years Built",
        "Then by": "Property type",
        "5th Percentile": "9",
        "25th Percentile": "42",
        "Mean": "52",
        "Median": "52",
        "75th Percentile": "72",
        "95th Percentile": "99",
        "Property Count": '100'
    },
    {
        "Display by": "All Years Built",
        "Then by": "Property type",
        "5th Percentile": "24",
        "25th Percentile": "42",
        "Mean": "52",
        "Median": "64",
        "75th Percentile": "88",
        "95th Percentile": "95"
    },
    {
        "Display by": "All Years Built",
        "Then by": "Property type",
        "5th Percentile": "16",
        "25th Percentile": "42",
        "Mean": "52",
        "Median": "52",
        "75th Percentile": "72",
        "95th Percentile": "97"
    },
    {
        "Display by": "All Years Built",
        "Then by": "Property type",
        "5th Percentile": "1",
        "25th Percentile": "2",
        "Mean": "52",
        "Median": "19",
        "75th Percentile": "51",
        "95th Percentile": "88"
    },
    {
        "Display by": "All Years Built",
        "Then by": "Property type",
        "5th Percentile": "55",
        "25th Percentile": "76",
        "Mean": "52",
        "Median": "85",
        "75th Percentile": "91",
        "95th Percentile": "87"
    }]