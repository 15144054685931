import React, { useState } from "react";
import "./App.scss";
import DataExplorerPageContainer from "./pages/dataExplorerPage";

import GlossaryIcon from "../src/assets/icons/glossary";
import QuestionIcon from "../src/assets/icons/question";
import DownloadIcon from "../src/assets/icons/download";
import logo from "./assets/images/logo.svg";
import { Tooltip } from "@mui/material";
import TagManager from "react-gtm-module";
import { AppProvider } from "./AppProvider";

const tagManagerArgs = {
  gtmId: "GTM-L8ZB",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const [metric, setMetric] = useState<string>("siteEui");
  const [isEnergyStarCertified, setIsEnergyStarCertified] = useState<
    boolean | undefined
  >(undefined);
  const [withMean, setWithMean] = useState<boolean>(false);
  const [withMedian, setWithMedian] = useState<boolean>(true);
  const [withFifthPercentile, setWithFifthPercentile] =
    useState<boolean>(false);
  const [withTwentyFifthPercentile, setWithTwentyFifthPercentile] =
    useState<boolean>(true);
  const [withSeventyFifthPercentile, setWithSeventyFifthPercentile] =
    useState<boolean>(true);
  const [withNinetyFifthPercentile, setWithNinetyFifthPercentile] =
    useState<boolean>(false);
  const [yearReported, setYearReported] = useState<number>(2022);
  const [yearBuiltGroups, setYearBuiltGroups] = useState<
    Array<any> | undefined
  >([]);
  const [stateProvinceNames, setStateProvinceNames] = useState<
    Array<any> | undefined
  >([]);
  const [gfaGroups, setGfaGroups] = useState<Array<any> | undefined>(undefined);
  const [gfaGroup2s, setGfaGroup2s] = useState<Array<any> | undefined>(
    undefined
  );
  const [groupBy, setGroupBy] = useState<any | undefined>("ptSubcategory");
  const [thenBy, setThenBy] = useState<any | undefined>(undefined);
  const [counties, setCounties] = useState<any | undefined>(undefined);
  const [weeklyHoursGroup, setWeeklyHoursGroup] = useState<
    Array<any> | undefined
  >([]);
  const [workerDensityGroups, setWorkerDensityGroups] = useState<
    Array<any> | undefined
  >([]);
  const [ptSubcategories, setPtSubcategories] = useState<
    Array<any> | undefined
  >(undefined);
  const [ptCategories, setPtCategories] = useState<Array<any> | undefined>(
    undefined
  );
  const [drawerOpen, setDrawerOpen] = React.useState({
    open: false,
    drawerView: "",
  });
  const [csa_city, setCsaCity] = useState<Array<any> | undefined>(undefined);
  const [csa_area, setCsaArea] = useState<Array<any> | undefined>(undefined);
  const [climateZone, setClimateZone] = useState<Array<any> | undefined>(
    undefined
  );
  const [location, setLocation] = useState<string>("state");
  const [isFilterPanelLoading, setIsFilterPanelLoading] = useState(true);

  const bannerMsg = process.env.REACT_APP_BANNER_MSG;
  const showBanner = !!bannerMsg;

  const trendsVariables = 
    {
      metric,
      groupBy,
      stateProvinceNames,
      counties,
      yearBuiltGroups,
      gfaGroups,
      gfaGroup2s,
      isEnergyStarCertified,
      workerDensityGroups,
      weeklyHoursGroup,
      climateZone,
      csa_city,
      csa_area,
      ptSubcategories,
      ptCategories
    };

  const resetFilters = () => {
    setYearBuiltGroups([]);
    setStateProvinceNames(undefined);
    setGfaGroups(undefined);
    setGfaGroup2s(undefined);
    setCounties(undefined);
    setWeeklyHoursGroup([]);
    setWorkerDensityGroups([]);
    setPtSubcategories(undefined);
    setPtCategories(undefined);
    setCsaCity(undefined);
    setCsaArea(undefined);
    setClimateZone(undefined);

    // Do we want to reset radio buttons too?
    setYearReported(2022)
    setIsEnergyStarCertified(undefined)
    setLocation("state")
  };

  return (
    <div className="App">
      <AppProvider trendsVariables={trendsVariables}>
      {showBanner && (
        <section
          className="usa-site-alert usa-site-alert--emergency"
          aria-label="Site alert"
        >
          <div className="usa-alert">
            <div className="usa-alert__body">
              <p className="usa-alert__text">{bannerMsg}</p>
            </div>
          </div>
        </section>
      )}

      <header className="header grid-row flex-justify flex-align-center width-full">
        <div className="headerLogo grid-row flex-justify-start flex-align-center flex-no-wrap">
          <a
            href="https://www.energystar.gov/buildings/resources-topic/portfolio-manager-data-explorer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              width={150}
              height={45}
              alt="Portfolio Manager Data Explorer logo"
            />
          </a>
        </div>
        <div className="headerButtons grid-row flex-justify-end flex-align-center flex-no-wrap">
          <div className="headerButtonsInner">
            <Tooltip
              title="Glossary (opens in new tab)"
              className="step--glossary"
            >
              <a
                href="https://portfoliomanager.energystar.gov/pm/glossary"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Glossary"
                className={`usa-button usa-button--unstyled iconButton`}
              >
                <GlossaryIcon scale={1.25} color="#005ea2" />
              </a>
            </Tooltip>
            <Tooltip title="Help" className="step--help">
              <button
                onClick={() =>
                  setDrawerOpen({
                    open: true,
                    drawerView: "Help",
                  })
                }
                aria-label={`Open Help`}
                className={`usa-button usa-button--unstyled iconButton`}
              >
                <QuestionIcon scale={0.85} color="#005ea2" />
              </button>
            </Tooltip>
            <button
              onClick={() =>
                setDrawerOpen({
                  open: true,
                  drawerView: "Download",
                })
              }
              aria-label={`Open Download`}
              className="download-button display-flex flex-align-center usa-button usa-button--outline step--download"
            >
              <DownloadIcon color="#005ea2" scale={1} />
              Download
            </button>
          </div>
        </div>
      </header>
      <main>
        <DataExplorerPageContainer
          resetFilters={resetFilters}
          setYearReported={setYearReported}
          setMetric={setMetric}
          setIsEnergyStarCertified={setIsEnergyStarCertified}
          setYearBuiltGroups={setYearBuiltGroups}
          setGfaGroups={setGfaGroups}
          setGfaGroup2s={setGfaGroup2s}
          metric={metric}
          yearReported={yearReported}
          //Use group2 for nested selects
          groupBy={groupBy}
          thenBy={thenBy}
          stateProvinceNames={stateProvinceNames}
          counties={counties}
          yearBuiltGroups={yearBuiltGroups}
          gfaGroups={gfaGroups}
          gfaGroup2s={gfaGroup2s}
          isEnergyStarCertified={isEnergyStarCertified}
          setGroupBy={setGroupBy}
          setThenBy={setThenBy}
          setStateProvinceNames={setStateProvinceNames}
          setCounties={setCounties}
          workerDensityGroups={workerDensityGroups}
          weeklyHoursGroup={weeklyHoursGroup}
          setWeeklyHoursGroup={setWeeklyHoursGroup}
          setWorkerDensityGroups={setWorkerDensityGroups}
          ptSubcategories={ptSubcategories}
          ptCategories={ptCategories}
          setPtSubcategories={setPtSubcategories}
          setPtCategories={setPtCategories}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          setWithMean={setWithMean}
          withMean={withMean}
          withMedian={withMedian}
          setWithMedian={setWithMedian}
          withFifthPercentile={withFifthPercentile}
          setWithFifthPercentile={setWithFifthPercentile}
          withTwentyFifthPercentile={withTwentyFifthPercentile}
          setWithTwentyFifthPercentile={setWithTwentyFifthPercentile}
          withSeventyFifthPercentile={withSeventyFifthPercentile}
          setWithSeventyFifthPercentile={setWithSeventyFifthPercentile}
          withNinetyFifthPercentile={withNinetyFifthPercentile}
          setWithNinetyFifthPercentile={setWithNinetyFifthPercentile}
          csa_area={csa_area}
          csa_city={csa_city}
          climateZone={climateZone}
          setCsaArea={setCsaArea}
          setCsaCity={setCsaCity}
          setClimateZone={setClimateZone}
          location={location}
          setLocation={setLocation}
          isFilterPanelLoading={isFilterPanelLoading}
          setIsFilterPanelLoading={setIsFilterPanelLoading}
        />
      </main>
      <footer className="footer grid-row flex-justify-end width-full">
        <a
          className="footer-hyperlink"
          href="https://energystar.my.site.com/PortfolioManager/s/contactsupport"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
        <a
          className="footer-hyperlink"
          href="https://www.epa.gov/accessibility/epa-accessibility-statement"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accessibility Statement
        </a>
        <a
          className="footer-hyperlink"
          href="http://www.energystar.gov/buildings/resources_topic/energy_star_portfolio_manager_data_explorer#AboutTheData"
          target="_blank"
          rel="noopener noreferrer"
        >
          About the Data
        </a>
        <a
          className="footer-hyperlink"
          href="https://www.energystar.gov/privacy_and_security_notice"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Statement
        </a>
      </footer>
      </AppProvider>
    </div>
  );
}

export default App;
