import React from 'react';
import sortArrow from './sort_arrow.svg';
import expandMore from './expand_more.svg';
import expandLess from './expand_less.svg';

interface SortArrowProps {
  ascending: boolean;
  selected: boolean;
}

const SortArrow: React.FC<SortArrowProps> = ({ ascending, selected }) => {
  return selected && ascending !== null ? (
    <img src={ascending ? expandLess : expandMore} className={`usa-icon ${ascending ? 'ascending' : 'descending'}`} alt="Sort arrow" aria-hidden="true" role="presentation" />
  ) : (
    <img src={sortArrow} className="usa-icon default" alt="Sort arrow" aria-hidden="true" role="presentation" />
  );
};

export default SortArrow;
