import React from 'react'



export default function QuestionIcon(QuestionIconProps: any) {
    return (
        <svg data-name="icons/usa-icons/help" xmlns="http://www.w3.org/2000/svg" width={36 * QuestionIconProps.scale} height={36 * QuestionIconProps.scale} viewBox={"0 0 " + (36 * QuestionIconProps.scale) + " " + (36 * QuestionIconProps.scale)}>
            <path data-name="Path 289" d="M17,2A15,15,0,1,0,32,17,15.005,15.005,0,0,0,17,2Zm1.5,25.5h-3v-3h3Zm3.1-11.625-1.35,1.38A5.106,5.106,0,0,0,18.5,21.5h-3v-.75a6.038,6.038,0,0,1,1.755-4.245l1.86-1.89A2.933,2.933,0,0,0,20,12.5a3,3,0,0,0-6,0H11a6,6,0,1,1,12,0A4.773,4.773,0,0,1,21.6,15.875Z" transform={`translate(0 0) scale(${QuestionIconProps.scale})`} fill="#005ea2" />
        </svg>




    )
}
