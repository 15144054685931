import React, { FC } from "react";
import styles from "./errorPage.module.scss";

interface ErrorPageProps {
  message: any;
}

const ErrorPage: FC<ErrorPageProps> = ({ message }) => {

  return (
    <div
      className={styles.ErrorPage}
      data-testid="ErrorPage"
    >
      <h1>Error</h1>
      <p>{message}</p>
    </div>
  );
};

export default ErrorPage;
