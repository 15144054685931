import React from 'react';
import './icons.scss'; // Import the CSS file

export default function InfoIcon(InfoIconProps: any) {
    const viewBox = `0 0 ${15.139 * InfoIconProps.scale} ${18.022 * InfoIconProps.scale}`;
    
    return (
        <svg
            className="icon-svg"
            data-name="icons/usa-icons/info_outline"
            xmlns="http://www.w3.org/2000/svg"
            width={15.139 * InfoIconProps.scale}
            height={18.022 * InfoIconProps.scale}
            viewBox={viewBox}
        >
            <path
                data-name="Path 307"
                d="M8,5.333H9.333V6.667H8ZM8,8H9.333v4H8Zm.667-6a6.667,6.667,0,1,0,6.667,6.667A6.669,6.669,0,0,0,8.667,2Zm0,12A5.333,5.333,0,1,1,14,8.667,5.34,5.34,0,0,1,8.667,14Z"
                transform={`translate(0 0) scale(${InfoIconProps.scale})`}
                fill={InfoIconProps.color}
            />
        </svg>
    );
}
