import {
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from "@apollo/client";
import React, { ReactNode, createContext, useContext, useReducer } from "react";
import { GET_TRENDS } from "./graphql/queries/trends";

interface AppState {
  isTrendsShown: boolean;
}
const initialState: AppState = {
  isTrendsShown: false,
};

interface QueryResult<TData> {
  data?: TData;
  error?: any;
  loading: boolean;
}

type AppAction =
  // | { type: "TOGGLE_TRENDS" }
  { type: "SET_IS_TRENDS_SHOWN"; payload: boolean };

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    // case "TOGGLE_TRENDS":
    //   return { ...state, isTrendsShown: !state.isTrendsShown };
    case "SET_IS_TRENDS_SHOWN":
      return { ...state, isTrendsShown: action.payload };
    default:
      return state;
  }
};

interface QueryContextValue<TData> {
  queryData: QueryResult<TData>;
  refetch: () => Promise<ApolloQueryResult<TData>>;
}

const TrendsQueryContext = createContext<QueryContextValue<any>>({
  queryData: { loading: true },
  refetch: () => Promise.resolve({} as ApolloQueryResult<any>),
});

const AppContext = createContext<AppState | undefined>(undefined);

const DispatchContext = createContext<React.Dispatch<AppAction> | undefined>(
  undefined
);

export const AppProvider: React.FC<{
  trendsVariables?: OperationVariables;
  children: ReactNode;
}> = ({ trendsVariables, children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { data, error, loading, refetch } = useQuery(GET_TRENDS, {
    variables: trendsVariables,
    skip: !state.isTrendsShown,
  });
  const trendsValue = { queryData: { data, error, loading }, refetch };
  return (
    <AppContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <TrendsQueryContext.Provider value={trendsValue}>
          {children}
        </TrendsQueryContext.Provider>
      </DispatchContext.Provider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export const useDispatchContext = () => {
  const context = useContext(DispatchContext);
  if (!context) {
    throw new Error("useDispatchContext must be used within an AppProvider");
  }
  return context;
};

export const useTrendsQueryResult = <TData = any,>(): QueryResult<TData> => {
  const context = useContext(TrendsQueryContext);
  if (!context) {
    throw new Error("useTrendsQueryResult must be used within an AppProvider");
  }
  return context.queryData;
};
