import React from 'react'

export default function CloseIcon(CloseIconProps: any) {
    return (
        <svg id="icons_usa-icons_close" data-name="icons/usa-icons/close" xmlns="http://www.w3.org/2000/svg" width={72 * CloseIconProps.scale} height={72 * CloseIconProps.scale} viewBox={"0 0 " + (72 * CloseIconProps.scale) + " " + (72 * CloseIconProps.scale)}>
             <g transform={`translate(0 0) scale(${CloseIconProps.scale})`}>
            <path id="Path_186" data-name="Path 186" d="M0,0H72V72H0Z" fill="none" />
            <path id="Path_187" data-name="Path 187" d="M47,9.23,42.77,5,26,21.77,9.23,5,5,9.23,21.77,26,5,42.77,9.23,47,26,30.23,42.77,47,47,42.77,30.23,26Z" transform="translate(10 10)" fill={CloseIconProps.color} />
            </g>
        </svg>



    )
}