import { Tooltip } from "@mui/material";
import React, {
  FC,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import GlossaryIcon from "../../assets/icons/glossary";
import styles from "./multiSelectDropdown.module.scss";

interface MultiSelectDropdownProps {
  label: any;
  data: any;
  name: any;
  setGroup1: any;
  setGroup2: any;
  group1: any;
  group2: any;
  glossaryLink: any;
}

// only applies to "Not in a CBSA (rural)"
const modifyDataStructure = (data: any) => {
  const modifiedData = data.map((item: any) => {
    if (item.children && item.value === "Not in a CBSA (rural)") {
      return { ...item, children: [] };
    }
    return item;
  });
  return modifiedData;
};

const MultiSelectDropdown: FC<MultiSelectDropdownProps> = ({
  glossaryLink,
  setGroup1,
  setGroup2,
  group1,
  group2,
  label,
  data,
  name,
}) => {
  const [multiSelectIsClear, setMultiSelectIsClear] = useState(true);
  const [updatedArray, setUpdatedArray] = useState(data);
  const selectedChildrenRef = useRef(0);

  const modifiedData = modifyDataStructure(data);



  useEffect(() => {
    if (selectedChildrenRef.current > 0) {
      setMultiSelectIsClear(false);
    } else {
      setMultiSelectIsClear(true);
    }
    // eslint-disable-next-line
  }, [selectedChildrenRef.current]);

  useEffect(() => {
    handleTabIndexes();
    const multiSelectInput = document.querySelector(
      `.${name} .dropdown .dropdown-trigger .tag-list .tag-item input`
    ) as any;
    multiSelectInput.type = "search";
  });

  const handleMultiSelectChange = useCallback(
    (e: any, selected: any) => {
      // Update count
      if (e._depth === 1) {
        if (e.checked) {
          selectedChildrenRef.current += 1;
        } else {
          selectedChildrenRef.current -= 1;
        }
      } else if (e._depth === 0) {
        const children = e._children.length;
        // only applies to "Not in a CBSA (rural)"
        if (children === 0) {
          if (e.checked) {
            selectedChildrenRef.current += 1;
          } else {
            selectedChildrenRef.current -= 1;
          }
          // end "Not in a CBSA (rural)" logic
        } else {
          if (e.checked) {
            selectedChildrenRef.current += children;
          } else {
            selectedChildrenRef.current -= children;
          }
        }
      }

      // Update table data based on group selection
      if (e.depth === 0 && e._children.length === 0) {
        setGroup1(
          selected.filter((x: any) => x._depth === 0).map((x: any) => x.value)
        );
        setGroup2(
          selected.filter((x: any) => x._depth === 0).map((x: any) => x.value)
        );
      } else {
        if (setGroup1 && setGroup2) {
          setGroup1(
            selected.filter((x: any) => x._depth === 1).map((x: any) => x.value)
          );
          setGroup2(
            selected.filter((x: any) => x._depth === 0).map((x: any) => x.value)
          );
        }
      }
    },
    [setGroup1, setGroup2]
  );

  let handleTabIndexes = () => {
    let multiSelectDropdownContent = document.getElementsByClassName(
      "dropdown-content"
    ) as any;
    let MSDCCheckboxes = document.getElementsByClassName(
      "checkbox-item"
    ) as any;
    let MSDCToggles = document.getElementsByClassName("toggle") as any;
    let searchInput = document.getElementsByClassName("search") as any;
    let searchTrigger = document.getElementById("rdts1_trigger") as any;
    Array.from(multiSelectDropdownContent).forEach((e: any) => {
      e.tabIndex = 0;
      e.ariaLabel = searchInput.placeholder + " checkbox tree";
    });
    searchInput.tabIndex = 0;
    searchTrigger.tabIndex = -1;
    setTimeout(function () {
      Array.from(MSDCCheckboxes).forEach((e: any) => {
        e.tabIndex = 0;
      });
    }, 5);
    Array.from(MSDCToggles).forEach((e: any) => {
      e.tabIndex = 0;
      e.ariaHidden = false;
      e.ariaLabel = "toggle checkbox group";
    });
  };

  let multiSelectDropDown = useMemo(() => {
    return (
      <DropdownTreeSelect
        onNodeToggle={handleTabIndexes}
        onChange={handleMultiSelectChange}
        showDropdown="always"
        keepTreeOnSearch={true}
        texts={{
          placeholder: `Search or select below`,
          label: `Search ${label}`,
        }}
        showPartiallySelected={true}
        data={modifiedData}
        key={data.label}
        className={`multiSelectDropdown treeSelect step--${name} ${name}`}
      />
    );
    // eslint-disable-next-line
  }, [data, updatedArray, name, handleMultiSelectChange]);

  let clearMultiSelect = () => {
    let copiedArray = updatedArray.slice(0);
    for (let i = 0; i < copiedArray.length; i++) {
      copiedArray[i].checked = false;
    }

    setUpdatedArray(copiedArray);
    selectedChildrenRef.current = 0;
    setGroup1(undefined);
    setGroup2(undefined);
  };

  const clearMultiSelectRef = useRef(clearMultiSelect);
  useEffect(() => {
    clearMultiSelectRef.current = clearMultiSelect;
  });
  
  useEffect(() => {
    if (group1 === undefined && group2 === undefined) {
      clearMultiSelectRef.current();
    }
  }, [group1, group2]);

  return (
    <div className={styles.MultiSelectDropdown}>
      <span className="usa-label grid-row flex-justify">
        <label id={`${name}-ts-label`} htmlFor={name}>
          {label}
        </label>
        {glossaryLink ? (
          <Tooltip title="Glossary (opens in new tab)">
            <a
              href={glossaryLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${label} Glossary`}
              className={`usa-button usa-button--unstyled iconButton`}
            >
              <GlossaryIcon scale={1} color="#182e4e" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
      </span>
      <div>{multiSelectDropDown}</div>
      <div className="grid-row flex-justify-end clear-button">
        <button
          id={`${label}-clear`}
          className={`usa-button--unstyled step--clear-${name}`}
          disabled={multiSelectIsClear}
          onClick={() => clearMultiSelect()}
        >
          Clear selection
        </button>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
