import React from 'react'


export default function GlossaryIcon(GlossaryIconProps: any) {
    return (

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={
          "0 0 " +
          21.9 * GlossaryIconProps.scale +
          " " +
          16.25 * GlossaryIconProps.scale
        }
        width={21.9 * GlossaryIconProps.scale}
        height={16.25 * GlossaryIconProps.scale}
      >
        <g transform={`translate(0 0) scale(${GlossaryIconProps.scale})`}>
          <rect
            x={0.45 * GlossaryIconProps.scale}
            y={2.67 * GlossaryIconProps.scale}
            width="21.9"
            height="16.25"
            fill={GlossaryIconProps.color}
          />
          <path
            fill={GlossaryIconProps.color}
            d="m21.45,16.25H.45c-.25,0-.45-.2-.45-.45V2.67c0-.25.2-.45.45-.45h21c.25,0,.45.2.45.45v13.12c0,.25-.2.45-.45.45ZM.9,15.34h20.09V3.12H.9v12.22Z"
          />
          <rect
            x={10.58 * GlossaryIconProps.scale}
            y={2.4 * GlossaryIconProps.scale}
            width="0.75"
            height="13.12"
            fill={GlossaryIconProps.color}
          />
          <path
            fill={"#fff"}
            d="m10.95,2.52C7.88,1.02,4.8.51,1.72,1.02v12.48c3.08-.33,6.16.17,9.23,1.51,3.07-1.33,6.15-1.84,9.23-1.51V1.02c-3.08-.5-6.15,0-9.23,1.51Z"
          />
          <path
            fill={"#fff"}
            d="m10.95,15.83c-.11,0-.23-.02-.33-.07-2.92-1.27-5.89-1.76-8.81-1.44-.23.02-.47-.05-.64-.21-.18-.16-.28-.38-.28-.62V1.02c0-.41.29-.75.7-.82,3.11-.51,6.26-.03,9.36,1.41C14.05.16,17.2-.31,20.31.2c.4.07.7.41.7.82v12.48c0,.24-.1.46-.28.62s-.41.23-.64.21c-2.92-.31-5.88.17-8.81,1.44-.11.05-.22.07-.33.07Zm-7.41-3.26c2.47,0,4.95.51,7.41,1.53,2.78-1.16,5.6-1.66,8.4-1.51V1.74c-2.66-.29-5.36.22-8.03,1.53-.23.11-.5.11-.73,0C7.92,1.96,5.22,1.45,2.55,1.74v10.86c.33-.02.66-.03.99-.03Z"
          />
          <path
            fill={GlossaryIconProps.color}
            d="m10.95,15.45c-.06,0-.12-.01-.18-.04-2.99-1.3-6.01-1.79-9-1.47-.13.01-.25-.03-.35-.11-.1-.09-.15-.21-.15-.34V1.02c0-.22.16-.41.38-.45,3.09-.51,6.22-.02,9.3,1.45C14.03.55,17.16.06,20.25.57c.22.04.38.22.38.45v12.48c0,.13-.05.25-.15.34-.1.09-.22.12-.35.11-2.98-.32-6.01.17-9,1.47-.06.03-.12.04-.18.04Zm-7.41-2.51c2.47,0,4.95.52,7.41,1.56,2.91-1.23,5.85-1.74,8.77-1.51V1.41c-2.85-.38-5.73.13-8.58,1.52-.13.06-.27.06-.4,0C7.9,1.53,5.02,1.02,2.18,1.41v11.59c.46-.04.91-.05,1.37-.05Z"
          />
          <path
            fill={GlossaryIconProps.color}
            d="m3.63,10.32l2.08-5.83h1.25l2.08,5.83h-1.17l-.48-1.35h-2.1l-.48,1.35h-1.18Zm1.93-2.38h1.55l-.77-2.32-.78,2.32Z"
          />
          <path
            fill={GlossaryIconProps.color}
            d="m13.2,10.44v-.58l3.02-4.35h-2.81v-1h4.52v.56l-3.06,4.37h3.02v1.01h-4.69Z"
          />
          <path
            fill={GlossaryIconProps.color}
            d="m10.95,15.38c-.21,0-.38-.17-.38-.38V2.4c0-.21.17-.38.38-.38s.38.17.38.38v12.6c0,.21-.17.38-.38.38Z"
          />
        </g>
      </svg>
    );
}