import React from 'react'


export default function SortAscendingIcon(SortAscendingIconProps: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24 * SortAscendingIconProps.scale} height={24 * SortAscendingIconProps.scale} viewBox={"0 0 " + (24 * SortAscendingIconProps.scale) + " " + (24 * SortAscendingIconProps.scale)}>
            <g transform={`translate(0 0) scale(${SortAscendingIconProps.scale})`}>
                <path d="M6.5,19.75c-.19,0-.38-.07-.53-.22l-2.5-2.5c-.29-.29-.29-.77,0-1.06s.77-.29,1.06,0l1.97,1.97,1.97-1.97c.29-.29,.77-.29,1.06,0s.29,.77,0,1.06l-2.5,2.5c-.15,.15-.34,.22-.53,.22Z" />
                <path d="M6.5,19.75c-.41,0-.75-.34-.75-.75V5c0-.41,.34-.75,.75-.75s.75,.34,.75,.75v14c0,.41-.34,.75-.75,.75Z" />
                <path d="M20,17.25H12c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75h8c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75Z" />
                <path d="M16,11.25h-4c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75h4c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75Z" />
                <path d="M14,8.25h-2c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75h2c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75Z" />
                <path d="M18,14.25h-6c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75h6c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75Z" />
            </g>
        </svg>
    )
}