
import React from 'react'


export default function ForwardIcon(ForwardIconProps: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={32 * ForwardIconProps.scale} height={32 * ForwardIconProps.scale} viewBox={"0 0 " + (32 * ForwardIconProps.scale) + " " + (32 * ForwardIconProps.scale)}>
            <path id="Path_1157" data-name="Path 1157" d="M20,4,17.18,6.82,28.34,18H4v4H28.34L17.18,33.18,20,36,36,20Z" transform={`translate(-2 -2) scale(${ForwardIconProps.scale})`} fill={ForwardIconProps.color} />
        </svg>
    )
}


