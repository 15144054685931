import React from 'react';
import './icons.scss'; // Import the SCSS file

export default function MinusIcon(MinusIconProps: any) {
    const viewBox = `0 0 ${24 * MinusIconProps.scale} ${24 * MinusIconProps.scale}`;
    
    return (
        <svg
            className="icon-svg" 
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            width={15.139 * MinusIconProps.scale}
            height={18.022 * MinusIconProps.scale}
        >
            <path
                d="M20,13H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h16c0.6,0,1,0.4,1,1S20.6,13,20,13z"
                transform={`translate(0 0) scale(${MinusIconProps.scale})`}
                fill={MinusIconProps.color}
            />
        </svg>
    );
}
