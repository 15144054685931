import React from 'react'


export default function DownloadIcon(DownloadIconProps: any) {
    return (
        <svg id="icons_usa-icons_file_download" data-name="icons/usa-icons/file_download" xmlns="http://www.w3.org/2000/svg" width={20 * DownloadIconProps.scale} height={20 * DownloadIconProps.scale} viewBox={"0 0 " + (20 * DownloadIconProps.scale) + " " + (20 * DownloadIconProps.scale)}>
            <path id="Path_253" data-name="Path 253" d="M16.667,8H13.333V3h-5V8H5l5.833,5.833ZM5,15.5v1.667H16.667V15.5Z" transform="translate(-0.833 -0.5)" fill={DownloadIconProps.color} />
        </svg>


    )
}

