import React, { FC, useEffect, useState } from "react";
import styles from "./dataExplorerPage.module.scss";
import MainContent from "../../components/mainContent/mainContent";
import { Drawer } from "@mui/material";
import CloseIcon from "../../../src/assets/icons/close";
import Help from "../../../src/components/help/help";
import Download from "../../../src/components/download/download";
import { tableJSON } from "../../assets/data/data";
import { Query } from "../../graphql/generated/graphql";
import Tour from "reactour";

import FilterSidepanelContainer from "../../components/filterSidepanel";
import PlusIcon from "../../assets/icons/plus";
import MinusIcon from "../../assets/icons/minus";

interface DataExplorerPageProps {
  resetFilters: any;
  results: Query | undefined;
  setMetric: any;
  metric: any;
  yearReported: any;
  setYearReported: any;
  setIsEnergyStarCertified: any;
  isEnergyStarCertified: any;
  setYearBuiltGroups: any;
  yearBuiltGroups: any;
  setGfaGroups: any;
  setGfaGroup2s: any;
  gfaGroup2s: any;
  gfaGroups: any;
  setThenBy: any;
  setGroupBy: any;
  groupBy: any;
  thenBy: any;
  loading: any;
  csa_area: any;
  csa_city: any;
  setCsaArea: any;
  setCsaCity: any;
  climateZone: any;
  setClimateZone: any;
  stateProvinceNames: any;
  setStateProvinceNames: any;
  setCounties: any;
  counties: any;
  weeklyHoursGroup: any;
  setWeeklyHoursGroup: any;
  setWorkerDensityGroups: any;
  workerDensityGroups: any;
  ptCategories: any;
  ptSubcategories: any;
  setPtCategories: any;
  setPtSubcategories: any;
  drawerOpen: any;
  setDrawerOpen: any;
  withMean: any;
  setWithMean: any;
  withMedian: any;
  setWithMedian: any;
  withTwentyFifthPercentile: any;
  setWithTwentyFifthPercentile: any;
  withSeventyFifthPercentile: any;
  setWithSeventyFifthPercentile: any;
  setWithFifthPercentile: any;
  setWithNinetyFifthPercentile: any;
  withFifthPercentile: any;
  withNinetyFifthPercentile: any;
  location: any;
  setLocation: any;
  isFilterPanelLoading: any;
  setIsFilterPanelLoading: any;
}

const DataExplorerPage: FC<DataExplorerPageProps> = ({
  resetFilters,
  drawerOpen,
  setDrawerOpen,
  setPtCategories,
  setPtSubcategories,
  ptCategories,
  ptSubcategories,
  workerDensityGroups,
  setWorkerDensityGroups,
  setWeeklyHoursGroup,
  weeklyHoursGroup,
  counties,
  setCounties,
  loading,
  setStateProvinceNames,
  stateProvinceNames,
  setClimateZone,
  setThenBy,
  setGroupBy,
  groupBy,
  thenBy,
  gfaGroups,
  gfaGroup2s,
  setGfaGroups,
  setGfaGroup2s,
  yearBuiltGroups,
  setYearBuiltGroups,
  setIsEnergyStarCertified,
  isEnergyStarCertified,
  yearReported,
  setYearReported,
  csa_area,
  csa_city,
  setCsaArea,
  setCsaCity,
  climateZone,
  setMetric,
  results,
  metric,
  setWithMean,
  withMean,
  withMedian,
  setWithMedian,
  withFifthPercentile,
  setWithFifthPercentile,
  withTwentyFifthPercentile,
  setWithTwentyFifthPercentile,
  withSeventyFifthPercentile,
  setWithSeventyFifthPercentile,
  withNinetyFifthPercentile,
  setWithNinetyFifthPercentile,
  location,
  setLocation,
  isFilterPanelLoading,
  setIsFilterPanelLoading,
}) => {
  const [view, setView] = useState<string>("Table");
  const [columns, setColumns] = useState<Array<any>>([]);
  const [chartType, setChartType] = useState<string | undefined>(undefined);
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [groupByResults, setGroupByResults] = useState<Array<string>>([]);
  const [thenByResults, setThenByResults] = useState<Array<string>>([]);
  const [readableThenBy, setReadableThenBy] = useState<Array<string>>([]);
  const [readableGroupBy, setReadableGroupBy] = useState<Array<string>>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [filtersVisibility, setFiltersVisibility] = useState(true);
  const [processedAllRow, setProcessedAllRow] = useState<any>({});
  const [entries, setEntries] = useState<Array<any>>([]);
  const [sortedChartData, setSortedChartData] = useState<Array<any>>([]);
  const [sortBy, setSortBy] = useState<{
    column: string;
    ascending: boolean | null;
  }>({ column: "", ascending: null });

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 640;
      if (ismobile !== isMobile) {
        setIsMobile(ismobile);
      }
    });
  }, [isMobile]);

  let toggleFilters = () => {
    setFiltersVisibility(!filtersVisibility);
  };

  const steps = [
    {
      selector: ".step--show-me",
      content: (
        <div>
          <h3>Show me</h3>
          <p>
            Select a metric. This will determine what results display in the
            table or chart, in combination with the filters applied.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--viewed-as",
      content: (
        <div>
          <h3>View as</h3>
          <p>
            Use this toggle to view results by table or chart. For the table
            select columns; for the chart select type.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--filtered-by",
      content: (
        <div>
          <h3>Filtered by</h3>
          <p>
            This section contains all the filters available to specify which
            properties to include in the results.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--property-type",
      content: (
        <div>
          <h3>Property type</h3>
          <p>
            Begin typing into the text box to search through options. Note that
            selecting no boxes is the same as selecting all boxes. Selecting no
            boxes means display will show all.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--clear-property-type",
      content: (
        <div>
          <h3>Clear selection</h3>
          <p>Select this to clear all selections made within a filter.</p>
        </div>
      ),
    },
    {
      selector: ".step--details-panel",
      content: (
        <div>
          <h3>Details panel</h3>
          <p>
            See all the filters currently applied to produce the results in the
            table or chart.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    {
      selector: ".step--display-by",
      content: (
        <div>
          <h3>Display first by and Then by (optional)</h3>
          <p>Use these dropdowns to decide how to group results.</p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    {
      selector: ".step--glossary",
      content: (
        <div>
          <h3>Glossary icon</h3>
          <p>
            Click on this icon to open the Portfolio Manager glossary for full
            definitions of terms used in this tool.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    {
      selector: ".step--download",
      content: (
        <div>
          <h3>Download icon</h3>
          <p>
            Click on this icon to see options for downloading the data used to
            generate the current query results.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    {
      selector: ".step--help",
      content: (
        <div>
          <h3>Help icon</h3>
          <p>
            Click on the question mark icon to take the tour or get more
            information about Portfolio Manager Data Explorer.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
  ];

  let handleTourClose = () => {
    setIsTourOpen(false);
  };

  // hide columns on load if checkboxes are false, update with checkbox filters
  let handleColumnChange = (object: any, e: any) => {
    if (view === "Table" && !loading) {
      let columnCheckboxes = document
        .getElementById("table-columns-checkboxes")
        ?.getElementsByTagName("input") as any;
      let table = document.getElementById("table") as any;
      let columns = document.getElementsByTagName("col") as any;
      let tableCells = table.querySelectorAll("td") as any;
      let selectColErrorMsg = document.getElementById(
        "select-col-error-msg"
      ) as any;

      tableCells.forEach((cell: any, i: any) => {
        let column = columns[i];
        if (columnCheckboxes) {
          for (let k = 0; k < columnCheckboxes.length; k++) {
            if (
              column &&
              columnCheckboxes[k].checked === false &&
              column.dataset.column === columnCheckboxes[k].value
            ) {
              column.style.visibility = "collapse";
            } else if (
              column &&
              columnCheckboxes[k].checked === true &&
              column.dataset.column === columnCheckboxes[k].value
            ) {
              column.style.visibility = "visible";
            }
          }
        }
      });

      const checkedCountFromState =
        withMean +
        withMedian +
        withFifthPercentile +
        withTwentyFifthPercentile +
        withSeventyFifthPercentile +
        withNinetyFifthPercentile;

      if (checkedCountFromState <= 0) {
        table.style.display = "none";
        selectColErrorMsg.style.display = "block";
      } else {
        table.style.display = "block";
        selectColErrorMsg.style.display = "none";
      }

      if (object !== undefined) {
        switch (object.currentTarget.value) {
          case "Mean":
            setWithMean(object.currentTarget.checked);
            break;
          case "Median":
            setWithMedian(object.currentTarget.checked);
            break;
          case "5th Percentile":
            setWithFifthPercentile(object.currentTarget.checked);
            break;
          case "25th Percentile":
            setWithTwentyFifthPercentile(object.currentTarget.checked);
            break;
          case "75th Percentile":
            setWithSeventyFifthPercentile(object.currentTarget.checked);
            break;
          case "95th Percentile":
            setWithNinetyFifthPercentile(object.currentTarget.checked);
            break;
        }
      }
    }

    setSortBy({ column: "", ascending: null });
  };

  const getHeadings = () => {
    return Object.keys(tableJSON[0]);
  };

  return (
    <div
      className={`${styles.DataExplorerPage} grid-row`}
      data-testid="DataExplorerPage"
    >
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={handleTourClose}
        accentColor="#009CA2"
        rounded={5}
        closeButtonAriaLabel={"Close tour"}
        className="tour"
        showNavigation={false}
        prevButton={"Back"}
        nextButton={"Next"}
        startAt={0}
        lastStepNextButton={"Done!"}
      />
      <Drawer
        anchor="right"
        open={drawerOpen.open}
        variant="temporary"
        onClose={() => setDrawerOpen({ open: false, drawerView: "" })}
        className="Drawer"
        PaperProps={{
          sx: {
            backgroundColor:
              drawerOpen.drawerView === "Help"
                ? "#009CA2"
                : drawerOpen.drawerView === "Download"
                ? "#007DA2"
                : "",
            color: "#fff",
            padding: "20px 40px",
            width: "485px",
          },
        }}
      >
        <span className="grid-row flex-justify-end">
          <button
            className="closeButton"
            onClick={() => setDrawerOpen({ open: false, drawerView: "" })}
            aria-label={`Close Drawer`}
          >
            <CloseIcon scale={0.5} color="#fff" />
          </button>
        </span>
        {drawerOpen.drawerView === "Help" ? (
          <Help isMobile={isMobile} setFiltersVisibility={setFiltersVisibility} setIsTourOpen={setIsTourOpen} setDrawerOpen={setDrawerOpen} />
        ) : (
          <Download
            metric={metric}
            yearReported={yearReported}
            isEnergyStarCertified={isEnergyStarCertified}
            groupByResults={groupByResults}
            thenByResults={thenByResults}
            groupBy={groupBy}
            thenBy={thenBy}
            results={results}
            readableGroupBy={readableGroupBy}
            readableThenBy={readableThenBy}
            yearBuiltGroups={yearBuiltGroups}
            weeklyHoursGroup={weeklyHoursGroup}
            workerDensityGroups={workerDensityGroups}
            gfaGroups={gfaGroups}
            gfaGroup2s={gfaGroup2s}
            stateProvinceNames={stateProvinceNames}
            withMean={withMean}
            withMedian={withMedian}
            withFifthPercentile={withFifthPercentile}
            withTwentyFifthPercentile={withTwentyFifthPercentile}
            withSeventyFifthPercentile={withSeventyFifthPercentile}
            withNinetyFifthPercentile={withNinetyFifthPercentile}
            ptSubcategories={ptSubcategories}
            ptCategories={ptCategories}
            csa_area={csa_area}
            csa_city={csa_city}
            climateZone={climateZone}
            location={location}
            entries={entries}
            processedAllRow={processedAllRow}
            sortedChartData={sortedChartData}
            view={view}
          />
        )}
      </Drawer>
      <div
        className={`${
          isTourOpen ? styles.disableScroll : styles.filterWrapper
        } desktop:grid-col-2 tablet:grid-col-4`}
      >
        <button
          className={`${
            isMobile ? "buttonFlex" : "buttonNone"
          } usa-button filters-button`}
          onClick={toggleFilters}
        >
          <i>
            {!filtersVisibility ? (
              <PlusIcon scale={1} color="#fff" />
            ) : (
              <MinusIcon scale={1} color="#fff" />
            )}
          </i>
          Filters
        </button>
        <div
          className={
            !isMobile ? "displayBlock" : filtersVisibility ? "displayBlock" : "displayNone"
          }
        >
          <FilterSidepanelContainer
            results={results}
            theadData={getHeadings()}
            tbodyData={tableJSON}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            setColumns={setColumns}
            columns={columns}
            setMetric={setMetric}
            view={view}
            setView={setView}
            setYearReported={setYearReported}
            yearReported={yearReported}
            setYearBuiltGroups={setYearBuiltGroups}
            yearBuiltGroups={yearBuiltGroups}
            setIsEnergyStarCertified={setIsEnergyStarCertified}
            isEnergyStarCertified={isEnergyStarCertified}
            gfaGroups={gfaGroups}
            setGfaGroups={setGfaGroups}
            gfaGroup2s={gfaGroup2s}
            setGfaGroup2s={setGfaGroup2s}
            stateProvinceNames={stateProvinceNames}
            setStateProvinceNames={setStateProvinceNames}
            setCsaArea={setCsaArea}
            setCsaCity={setCsaCity}
            setClimateZone={setClimateZone}
            counties={counties}
            setCounties={setCounties}
            weeklyHoursGroup={weeklyHoursGroup}
            setWeeklyHoursGroup={setWeeklyHoursGroup}
            workerDensityGroups={workerDensityGroups}
            setWorkerDensityGroups={setWorkerDensityGroups}
            setPtSubcategories={setPtSubcategories}
            setPtCategories={setPtCategories}
            setChartType={setChartType}
            handleColumnChange={handleColumnChange}
            setWithMean={setWithMean}
            withMean={withMean}
            withMedian={withMedian}
            setWithMedian={setWithMedian}
            withTwentyFifthPercentile={withTwentyFifthPercentile}
            setWithTwentyFifthPercentile={setWithTwentyFifthPercentile}
            withSeventyFifthPercentile={withSeventyFifthPercentile}
            setWithSeventyFifthPercentile={setWithSeventyFifthPercentile}
            setThenBy={setThenBy}
            setWithFifthPercentile={setWithFifthPercentile}
            setWithNinetyFifthPercentile={setWithNinetyFifthPercentile}
            setLocation={setLocation}
            location={location}
            isFilterPanelLoading={isFilterPanelLoading}
            setIsFilterPanelLoading={setIsFilterPanelLoading}
            climateZone={climateZone}
            ptCategories={ptCategories}
            ptSubcategories={ptSubcategories}
            csa_city={csa_city}
            csa_area={csa_area}
          />
        </div>
      </div>
      <div
        className={`${styles.mainContentWrapper} desktop:grid-col-10 tablet:grid-col-8`}
      >
        <MainContent
          resetFilters={resetFilters}
          csa_area={csa_area}
          csa_city={csa_city}
          climateZone={climateZone}
          location={location}
          isMobile={isMobile}
          setReadableThenBy={setReadableThenBy}
          setReadableGroupBy={setReadableGroupBy}
          readableThenBy={readableThenBy}
          readableGroupBy={readableGroupBy}
          groupByResults={groupByResults}
          thenByResults={thenByResults}
          setGroupByResults={setGroupByResults}
          setThenByResults={setThenByResults}
          handleColumnChange={handleColumnChange}
          chartType={chartType}
          ptSubcategories={ptSubcategories}
          ptCategories={ptCategories}
          workerDensityGroups={workerDensityGroups}
          weeklyHoursGroup={weeklyHoursGroup}
          stateProvinceNames={stateProvinceNames}
          gfaGroup2s={gfaGroup2s}
          gfaGroups={gfaGroups}
          loading={loading}
          setGroupBy={setGroupBy}
          setThenBy={setThenBy}
          yearBuiltGroup={yearBuiltGroups}
          groupBy={groupBy}
          thenBy={thenBy}
          isEnergyStarCertified={isEnergyStarCertified}
          yearReported={yearReported}
          columns={columns}
          view={view}
          metric={metric}
          theadData={getHeadings()}
          results={results}
          count={results?.getResults?.count}
          isFilterPanelLoading={isFilterPanelLoading}
          processedAllRow={processedAllRow}
          setProcessedAllRow={setProcessedAllRow}
          entries={entries}
          setEntries={setEntries}
          setSortedChartData={setSortedChartData}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      </div>
    </div>
  );
};

export default DataExplorerPage;
