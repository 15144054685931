import { gql } from "@apollo/client";

export const GET_TRENDS = gql`
  query getTrends(      
    $metric: Metric!
    $groupBy: Grouping!
    $stateProvinceNames: [String]
    $counties: [String]
    $yearBuiltGroups: [String]
    $gfaGroups: [String]
    $gfaGroup2s: [String]
    $csa_city: [String]
    $csa_area: [String]
    $climateZone: [String]
    $isEnergyStarCertified: Boolean
    $workerDensityGroups: [String]
    $weeklyHoursGroup: [String]
    $ptCategories: [String]
    $ptSubcategories: [String]) {
    getTrends(
      metric: $metric
      groupBy: $groupBy
      stateProvinceNames: $stateProvinceNames
      counties: $counties
      yearBuiltGroups: $yearBuiltGroups
      gfaGroups: $gfaGroups
      gfaGroup2s: $gfaGroup2s
      csa_city: $csa_city
      csa_area: $csa_area
      climateZone: $climateZone
      isEnergyStarCertified: $isEnergyStarCertified
      workerDensityGroups: $workerDensityGroups
      weeklyHoursGroup: $weeklyHoursGroup
      ptCategories: $ptCategories
      ptSubcategories: $ptSubcategories
     ) {
      count
      results {
        group
        yearreported
        median
      }
    }
  }
  `;