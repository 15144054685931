import React, { FC } from 'react';
import styles from './charts.module.scss';
import BarChart from '../barChart/barChart';
import BoxAndWhiskerPlot from '../boxAndWhiskerPlot/boxAndWhiskerPlot';

interface ChartsProps {
  chartType: any;
  groupBy: any;
  results: any;
  readableGroupBy: any;
  metric: any;
  max: any;
  min: any;
  isMobile: any;
  setSortedChartData: any;
  caption: any;
  chartCount: any;
  chartTitle: any;
}

const Charts: FC<ChartsProps> = ({chartCount, chartTitle, isMobile, min, max, readableGroupBy, metric, chartType, groupBy, results, setSortedChartData, caption }) => {


  return (
    <div className={styles.Charts} id="Charts">
      {chartType === "Bar Charts" ?
        <BarChart chartCount={chartCount} chartTitle={chartTitle} setSortedChartData={setSortedChartData} isMobile={isMobile} min={min} max={max} readableGroupBy={readableGroupBy} metric={metric} results={results} groupBy={groupBy} caption={caption} /> :
        chartType === "Box-and-Whisker Plots" ?
          <BoxAndWhiskerPlot chartCount={chartCount} chartTitle={chartTitle} isMobile={isMobile} readableGroupBy={readableGroupBy} metric={metric} results={results} groupBy={groupBy} setSortedChartData={setSortedChartData} caption={caption} /> :
          <p>No Chart Selected</p>
      }
    </div>
  )
};

export default Charts;
