import { useQuery } from "@apollo/client";
import { graphql } from "../../graphql/generated/gql";
import FilterSidepanel from "./filterSidepanel";
import { FC } from "react";
import { LinearProgress } from "@mui/material";

// NOTE: changes made to the query need to be run through npm run codemon or the codemon must be watching for changes
const getFiltersQuery = graphql(`
  query GetPropertyTypeCategories {
    getPropertyTypeCategories {
      ptCategory
      ptSubcategory
    }
    getClimateZones {
      climateZone
    }
    getCsa {
      csa_city
      csa_area
    }
  }
`);

interface FilterSidepanelContainerProps {
  view: any;
  setView: any;
  setMetric: any;
  columns: Array<any>;
  setColumns: any;
  drawerOpen: any;
  setDrawerOpen: any;
  theadData: any;
  tbodyData: any;
  setIsEnergyStarCertified: any;
  isEnergyStarCertified: any;
  setYearReported: any;
  yearReported: any;
  yearBuiltGroups: any;
  setYearBuiltGroups: any;
  gfaGroups: any;
  setGfaGroups: any;
  gfaGroup2s: any;
  setGfaGroup2s: any;
  results: any;
  stateProvinceNames: any;
  setStateProvinceNames: any;
  counties: any;
  setCounties: any;
  setWeeklyHoursGroup: any;
  weeklyHoursGroup: any;
  workerDensityGroups: any;
  setWorkerDensityGroups: any;
  setPtCategories: any;
  setPtSubcategories: any;
  setChartType: any;
  handleColumnChange: any;
  withMean: any;
  setWithMean: any;
  withMedian: any;
  setWithMedian: any;
  withTwentyFifthPercentile: any;
  setWithTwentyFifthPercentile: any;
  withSeventyFifthPercentile: any;
  setWithSeventyFifthPercentile: any;
  setThenBy: any;
  setWithFifthPercentile: any;
  setWithNinetyFifthPercentile: any;
  setCsaArea: any;
  setCsaCity: any;
  setClimateZone: any;
  location: any;
  setLocation: any;
  isFilterPanelLoading: any;
  setIsFilterPanelLoading: any;
  climateZone: any;
  ptSubcategories: any;
  ptCategories: any;
  csa_city: any;
  csa_area: any;
}

const FilterSidepanelContainer: FC<FilterSidepanelContainerProps> = ({
  setMetric,
  view,
  setView,
  setYearReported,
  setYearBuiltGroups,
  yearBuiltGroups,
  setIsEnergyStarCertified,
  setGfaGroups,
  setGfaGroup2s,
  setStateProvinceNames,
  weeklyHoursGroup,
  setWeeklyHoursGroup,
  workerDensityGroups,
  setWorkerDensityGroups,
  setPtSubcategories,
  setPtCategories,
  setChartType,
  handleColumnChange,
  setWithMean,
  setWithMedian,
  setWithTwentyFifthPercentile,
  setWithSeventyFifthPercentile,
  setThenBy,
  setWithFifthPercentile,
  setWithNinetyFifthPercentile,
  setCsaArea,
  setCsaCity,
  setClimateZone,
  location,
  setLocation,
  setIsFilterPanelLoading,
  climateZone,
  stateProvinceNames,
  ptSubcategories,
  ptCategories,
  gfaGroups,
  gfaGroup2s,
  csa_city,
  csa_area,
  yearReported,
  isEnergyStarCertified,
}) => {
  const { data, loading, error } = useQuery(getFiltersQuery);
  if (error) return <p>Error: {error.message}</p>;
  if (loading)
    return (
      <div
      className="linear-progress-container"
      >
        <LinearProgress />
      </div>
    );
  return (
    <FilterSidepanel
      filterData={data}
      setPtCategories={setPtCategories}
      setPtSubcategories={setPtSubcategories}
      workerDensityGroups={workerDensityGroups}
      setWorkerDensityGroups={setWorkerDensityGroups}
      setWeeklyHoursGroup={setWeeklyHoursGroup}
      weeklyHoursGroup={weeklyHoursGroup}
      setStateProvinceNames={setStateProvinceNames}
      view={view}
      setView={setView}
      setMetric={setMetric}
      setIsEnergyStarCertified={setIsEnergyStarCertified}
      setYearReported={setYearReported}
      yearBuiltGroups={yearBuiltGroups}
      setYearBuiltGroups={setYearBuiltGroups}
      setGfaGroups={setGfaGroups}
      setGfaGroup2s={setGfaGroup2s}
      setChartType={setChartType}
      handleColumnChange={handleColumnChange}
      setWithMean={setWithMean}
      setWithMedian={setWithMedian}
      setWithTwentyFifthPercentile={setWithTwentyFifthPercentile}
      setWithSeventyFifthPercentile={setWithSeventyFifthPercentile}
      setThenBy={setThenBy}
      setWithFifthPercentile={setWithFifthPercentile}
      setWithNinetyFifthPercentile={setWithNinetyFifthPercentile}
      setClimateZone={setClimateZone}
      setCsaArea={setCsaArea}
      setCsaCity={setCsaCity}
      setLocation={setLocation}
      location={location}
      setIsFilterPanelLoading={setIsFilterPanelLoading}
      climateZone={climateZone}
      stateProvinceNames={stateProvinceNames}
      ptSubcategories={ptSubcategories}
      ptCategories={ptCategories}
      gfaGroups={gfaGroups}
      gfaGroup2s={gfaGroup2s}
      csa_city={csa_city}
      csa_area={csa_area}
      yearReported={yearReported}
      isEnergyStarCertified={isEnergyStarCertified}
    />
  );
};

export default FilterSidepanelContainer;
